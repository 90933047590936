import { createApp } from 'vue'
import App from './App.vue'
import router from './router' // Importe o router aqui
import "bootstrap/dist/css/bootstrap.css"

// Obtém a URL atual do navegador
const currentUrl = window.location.href
if (currentUrl.includes('idoconversa.com.br')){
    import('@/styles/idoconversa.css')
}
//OUTROS ESTILOS
if (currentUrl.includes('chipchat.com.br')) {
    import('@/styles/chatchip.css')
}

if (currentUrl.includes('localhost')){
    import('@/styles/hubot.css')
}
//OUTROS ESTILOS
if (currentUrl.includes('hubot.app.br')) {
    import('@/styles/hubot.css')
}

const app = createApp(App)
app.use(router) // Use o router
app.mount('#app')

import "bootstrap/dist/js/bootstrap.js"
