<template>
<section v-if="config.videoSection.visible" class="d-flex align-items-center bg-white pt-0">
                <div class="container d-flex align-items-center">
                    <div class="row d-flex align-items-center">
                        <div class="col-12 col-sm-8 offset-sm-2">   
                            <h1 class="mb-3">Por dentro da <strong>plataforma</strong></h1> 
                            <div class="embed-responsive embed-responsive-16by9">
  <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/CooewZqDGBo?si=1Ucp-lkNuJDeNfjQ"></iframe>
</div>  

                        </div>
                        <div class="col-12 col-sm-8 offset-sm-2">
                             <Carousel :items-to-show="3.5" :wrap-around="true">
                                <Slide v-for="(image, index) in images" :key="index">

                                    <div class="carousel__item videos">
                                        <a :href="image.videoUrl" target="_blank">
                                            <img class="img-fluid" src="../assets/rendering.png" :alt="image.alt" />
                                        </a>
                                        <p>{{ image.alt }}</p>
                                    </div>
                                   
                                </Slide>
                        </Carousel>
                        </div>
                    </div>
                </div>
            </section>

</template>

<script>
// If you are using PurgeCSS, make sure to whitelist the carousel CSS classes
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide } from 'vue3-carousel'
import { getConfig } from '@/config';

export default {
  name: 'sectionVideos',
  components: {
    Carousel,
    Slide
  },
  data() {
    return {
    currentUrl: window.location.href,
      config: {},
      images: [
        {
          videoUrl: 'https://www.youtube.com/watch?v=c1Tr5ImZ9f4',
          alt: "Gestão de equipe",
        },
        {
          videoUrl: 'https://www.youtube.com/watch?v=2i9v-5GAFTQ', 
          alt: "Setores e filas",
        },
        {
          videoUrl: 'https://www.youtube.com/watch?v=uJCWgXf3bG4', 
          alt: "Conectando o Whatsapp",
        },
        {
          videoUrl: 'https://www.youtube.com/watch?v=XwPuXWZGES8',  
          alt: "Fluxo de mensagens",
        }
      ]
    };
  },
  
  created() {
    this.config = getConfig(this.currentUrl);
  }
  
}
</script>
<style scoped>
.carousel__item img{
  max-width: 100%;
  padding: 0px 8px;
}
.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
  border: 3px solid aliceblue;
  border-radius: 16px;
}
.embed-responsive-16by9::before {
  padding-top: 56.25%;
}
.embed-responsive::before {
  display: block;
  content: "";
}
.embed-responsive .embed-responsive-item, .embed-responsive embed, .embed-responsive iframe, .embed-responsive object, .embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}
.carousel__item.videos {
  border: 1px solid aliceblue;
  border-radius: 16px;
  font-weight: 300;
  line-height: 1.4;
  font-size: 14px;
  padding: 8px 8px;
  margin:0px 4px;
}
.carousel__item.videos:hover {
  background-color: #f3efff;
  border: none;
}

.videos p {
  max-width: 140px;
}
</style>
