<template>
  <section v-if="config && config.planos" class="price_plan_area section_padding_130_80" id="planos">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-12 col-sm-8">
          <!-- Section Heading-->
          <div class="section-heading text-center wow fadeInUp" data-wow-delay="0.2s" style="visibility: visible; animation-delay: 0.2s; animation-name: fadeInUp;">
            <h3>{{ config.secConquisteTitle }}</h3>
            <p class="mb-4">{{ config.secConquisteDescript }}</p>
            <div class="line"></div>
          </div>
        </div>
      </div>
      <div  class="row justify-content-center">
        <div v-for="(plan, index) in config.planos" :key="index" class="col-12 col-sm-8 col-md-6 col-lg-4">
          <div class="single_price_plan wow fadeInUp" data-wow-delay="0.2s" style="visibility: visible; animation-delay: 0.2s; animation-name: fadeInUp;">
            <div class="title">
              <h2>{{ plan.title }}</h2>
              <p v-if="!config.testButton.visible">{{ plan.trial }}</p>
              <div class="line"></div>
            </div>
            <div class="price">
              <h4>{{ plan.price }}</h4>
            </div>
            <div class="description">
              <p v-for="(feature, featureIndex) in plan.features" :key="featureIndex">

                <svg v-if="feature.active" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="20" height="20" viewBox="0 0 20 20" >
<path fill="#2ecc71" d="M9.5 20c-2.538 0-4.923-0.988-6.718-2.782s-2.782-4.18-2.782-6.717c0-2.538 0.988-4.923 2.782-6.718s4.18-2.783 6.718-2.783c2.538 0 4.923 0.988 6.718 2.783s2.782 4.18 2.782 6.718-0.988 4.923-2.782 6.717c-1.794 1.794-4.18 2.782-6.718 2.782zM9.5 2c-4.687 0-8.5 3.813-8.5 8.5s3.813 8.5 8.5 8.5 8.5-3.813 8.5-8.5-3.813-8.5-8.5-8.5z"></path>
<path fill="#2ecc71" d="M7.5 14.5c-0.128 0-0.256-0.049-0.354-0.146l-3-3c-0.195-0.195-0.195-0.512 0-0.707s0.512-0.195 0.707 0l2.646 2.646 6.646-6.646c0.195-0.195 0.512-0.195 0.707 0s0.195 0.512 0 0.707l-7 7c-0.098 0.098-0.226 0.146-0.354 0.146z"></path>
</svg>

<svg v-if="!feature.active" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="20" height="20" viewBox="0 0 20 20">
<path fill="#e74c3c" d="M14.332 14.126l-4.080-3.626 4.080-3.626c0.206-0.183 0.225-0.499 0.042-0.706s-0.499-0.225-0.706-0.042l-4.168 3.705-4.168-3.705c-0.206-0.183-0.522-0.165-0.706 0.042s-0.165 0.522 0.042 0.706l4.080 3.626-4.080 3.626c-0.206 0.183-0.225 0.499-0.042 0.706 0.099 0.111 0.236 0.168 0.374 0.168 0.118 0 0.237-0.042 0.332-0.126l4.168-3.705 4.168 3.705c0.095 0.085 0.214 0.126 0.332 0.126 0.138 0 0.275-0.057 0.374-0.168 0.183-0.206 0.165-0.522-0.042-0.706z"></path>
<path fill="#e74c3c" d="M9.5 20c-2.538 0-4.923-0.988-6.718-2.782s-2.782-4.18-2.782-6.717c0-2.538 0.988-4.923 2.782-6.718s4.18-2.783 6.718-2.783c2.538 0 4.923 0.988 6.718 2.783s2.782 4.18 2.782 6.718-0.988 4.923-2.782 6.717c-1.794 1.794-4.18 2.782-6.718 2.782zM9.5 2c-4.687 0-8.5 3.813-8.5 8.5s3.813 8.5 8.5 8.5 8.5-3.813 8.5-8.5-3.813-8.5-8.5-8.5z"></path>
</svg>

                {{ feature.text }}
              </p>
            </div>
            <div class="button">
              <a class="btn btn-primary btn-lg" :href="config.signupUrl">
                <span v-if="config.testButton.visible">{{config.testButton.textStart}}</span>
                <span v-if="!config.testButton.visible">{{config.testButton.textTrial}}</span>
            </a>
            </div>
          </div>
        </div>
      </div>
      <div v-if="config.testButtonWhiteLabel.visible" class="col-12">
        <div class="single_price_plan wow fadeInUp" data-wow-delay="0.2s" style="visibility: visible; animation-delay: 0.2s; animation-name: fadeInUp;">
          <div class="row">
            <div class="col-12 col-sm-10">
              <div class="title">
                <h1>{{ config.whiteLabel.title }}</h1>
                <p style="text-align:left;">{{ config.whiteLabel.description }}</p>
                <div class="line" style="margin-left:0px;"></div>
              </div>
            </div>
            <div class="col-12 col-sm-2 d-flex">
              <div class="button"><a class="btn btn-primary btn-lg" href="#">{{ config.whiteLabel.price }}</a></div>
            </div>
            <div class="col-12 col-sm-12">
              <div class="mb-0">
                <p>{{ config.whiteLabel.details }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { getConfig } from '@/config';

export default {
  name: 'sectionPlanos',
  data() {
    return {
      currentUrl: window.location.href,
      config: {} // Inicializa como objeto vazio para evitar erros de acesso null
    };
  },
  created() {
    this.config = getConfig(this.currentUrl);
  }
};
</script>

<style scoped>
/* Seu estilo aqui */
.single_price_plan {
  position: relative;
  z-index: 1;
  border-radius: 0.5rem 0.5rem 0 0;
  -webkit-transition-duration: 500ms;
  transition-duration: 500ms;
  margin-bottom: 50px;
  background-color: var(--white-background-color);
  padding: 3rem 4rem;
}
@media only screen and (min-width: 992px) {
  .single_price_plan {padding: 3rem;}
  h1, p {text-align:left;}
}
@media only screen and (max-width: 575px) {
  h1, p {text-align:center;}
  .single_price_plan {
    padding: 3rem;
  }
}
.single_price_plan .title {
  text-transform: capitalize;
  -webkit-transition-duration: 500ms;
  transition-duration: 500ms;
  margin-bottom: 2rem;
}
.single_price_plan .title span {
  color: var(--white-text-color);
  padding: 0.2rem 0.6rem;
  font-size: 12px;
  text-transform: uppercase;
  background-color: var(--green-dark-color);
  display: inline-block;
  margin-bottom: 0.5rem;
  border-radius: 0.25rem;
}
.single_price_plan .title h3 {
  font-size: 1.25rem;
}
.single_price_plan .title p {
  font-weight: 300;
  line-height: 1;
  font-size: 14px;
  text-align: center;
}
.single_price_plan .title .line {
  width: 80px;
  height: 4px;
  border-radius: 10px;
  background-color: #3f43fd;
  margin: auto;
}
.single_price_plan .price {
  margin-bottom: 1.5rem;
}
.single_price_plan .price h4 {
  position: relative;
  z-index: 1;
  font-size: 2.4rem;
  line-height: 1;
  margin-bottom: 0;
  color: #3f43fd;
  display: inline-block;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-color: transparent;
  background-image: -webkit-gradient(linear, left top, right top, from(#e24997), to(#2d2ed4));
  background-image: linear-gradient(90deg, #e24997, #2d2ed4);
}
.single_price_plan .description {
  position: relative;
  margin-bottom: 1.5rem;
}
.single_price_plan .description p {
  line-height: 16px;
  margin: 0;
  padding: 10px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -ms-grid-row-align: center;
  align-items: center;
}
.single_price_plan .description p svg {
  color: #2ecc71;
  fill: #2ecc71;
  border: #2ecc71;
  margin-right: 0.5rem;
}
.single_price_plan .description p .lni-close {
  color: #e74c3c;
}
.single_price_plan.active,
.single_price_plan:hover,
.single_price_plan:focus {
  -webkit-box-shadow: 0 6px 50px 8px rgba(21, 131, 233, 0.15);
  box-shadow: 0 6px 50px 8px rgba(21, 131, 233, 0.15);
}
.single_price_plan .side-shape img {
  position: absolute;
  width: auto;
  top: 0;
  right: 0;
  z-index: -2;
}
.section-heading h3 {
  margin-bottom: 1rem;
  font-size: 3.125rem;
  letter-spacing: -1px;
}
.section-heading p {
  margin-bottom: 0;
  font-size: 1.25rem;
  text-align:center;
}
.section-heading .line {
  width: 120px;
  height: 5px;
  margin: 30px auto 0;
  border-radius: 6px;
  background: #2d2ed4;
  background: -webkit-gradient(linear, left top, right top, from(#e24997), to(#2d2ed4));
  background: linear-gradient(to right, #e24997, #2d2ed4);
}
.single_price_plan a {
  color: var(--white-text-color) !important;
}
</style>
