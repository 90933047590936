<!-- src/components/HomePage.vue -->
<template>
  <div>
    <IntroSite />
    <sectionConquiste />
    <sectionNichos />
    <sectionFeatures />
    <sectionMais />
    <sectionVideos />
    <sectionPlanos />
    <sectionFaq />
  </div>
</template>

<script>
import IntroSite from './IntroSite.vue'
import sectionConquiste from './sectionConquiste.vue'
import sectionNichos from './sectionNichos.vue'
import sectionFeatures from './sectionFeatures.vue'
import sectionMais from './sectionMais.vue'
import sectionVideos from './sectionVideos.vue'
import sectionPlanos from './sectionPlanos.vue'
import sectionFaq from './sectionFaq.vue'

export default {
  name: 'HomePage',
  components: {
    IntroSite,
    sectionConquiste,
    sectionNichos,
    sectionFeatures,
    sectionMais,
    sectionVideos,
    sectionPlanos,
    sectionFaq
  }
}
</script>
