<template>
                <footer>
            <div class="container">
                <div class="row">
                    <div class="col-12 text-center">
                        <small>{{config.footerMessage}} </small>
                    </div>
                </div>
            </div>
        </footer>
        <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/font-awesome/4.5.0/css/font-awesome.min.css">
<a :href="config.faq.contactUrl" class="float" target="_blank">
<i class="fa fa-whatsapp my-float"></i>
</a>
</template>

<script>
import { getConfig } from '@/config';
export default {
  name: 'pageFooter',
  props: {
    msg: String
  },
   data() {
        return {
            currentUrl: window.location.href,
            config: {}
        };
    },
    created() {
        this.config = getConfig(this.currentUrl);
        console.log("Config loaded: ", this.config);
    }
};

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
footer {
    background-color: var(--navy-baground-color);
    color: var(--white-text-color);
    padding: 36px 0px;
  }
  .float{
	position:fixed;
	width:60px;
	height:60px;
	bottom:40px;
	right:40px;
	background-color:#25d366;
	color: var(--white-text-color) !important;
	border-radius:50px;
	text-align:center;
  font-size:30px;
	box-shadow: 2px 2px 3px #999;
  z-index:100;
}

.my-float{
	margin-top:16px;
}
</style>
