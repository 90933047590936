// src/representatives.ts
export const representatives = {
    diegosouzasampaio: {
      nameTitle: 'Diego Sampaio Representante',
      pageTitle: 'Bem-vindo ao site de Diego Sampaio!',
      chatUrl: 'https://api.whatsapp.com/send/?phone=5511999999999?text=Contato%20com%20Diego',
      signupUrl: '/assine',
      secConquisteTitle: 'Impulsione seu negócio com Diego!',
      secConquisteDescript: 'Desenvolvido por idoconversa para empresários que desejam automatizar o atendimento ao cliente.',
      footerMessage: 'Copyright © 2024 IdoConversa. Todos os direitos reservados.'
    },
    fabioleite: {
      nameTitle: 'Fabio Ferreira Leite',
      pageTitle: 'Bem-vindo ao site representante Fabio Ferreira Leite!',
      chatUrl: 'https://api.whatsapp.com/send/?phone=55359924236?text=Contato%20com%20Jane',
      signupUrl: 'https://api.whatsapp.com/send/?phone=553599242368&text=Contato+Vindo+do+Site+Idoconversa',
      secConquisteTitle: 'Impulsione seu negócio com Fabio Ferreira Leite!',
      secConquisteDescript: 'Desenvolvido por idoconversa para empresas que querem inovar.',
      footerMessage: 'Copyright © 2024 Jane Smith. Todos os direitos reservados.'
    }
  };
  