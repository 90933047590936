<template>
    <section class="d-flex align-items-center bg-white centered">
        <div class="container">
            <div class="row">
                <div class="col-12 col-sm-6 mb-4">
                    <h4 class="faq-title">{{ config.faq.title }}</h4>
                    <h1 class="mb-3">{{ config.faq.subtitle }}</h1>
                    <p class="mb-3">{{ config.faq.description }}</p>
                    <a :href="config.faq.contactUrl" class="btn-center btn btn-primary btn-lg">     <i class="fa-brands fa-whatsapp"></i> Fale conosco agora
                    </a>
                </div>
                <div class="col-12 col-sm-6">
                    <div class="accordion accordion-flush" id="faq">
                        <div v-for="(item, index) in config.faq.items" :key="index" class="accordion-item">
                            <h2 class="accordion-header" :id="'flush-heading' + index">
                                <button
                                    class="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    :data-bs-target="'#flush-collapse' + index"
                                    aria-expanded="true"
                                    :aria-controls="'flush-collapse' + index"
                                >
                                    {{ item.question }}
                                </button>
                            </h2>
                            <div
                                :id="'flush-collapse' + index"
                                class="accordion-collapse collapse"
                                :aria-labelledby="'flush-heading' + index"
                                data-bs-parent="#accordionFlushExample"
                            >
                                <div class="accordion-body">
                                    {{ item.answer }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { getConfig } from '@/config';

export default {
    name: 'sectionFaq',
    data() {
        return {
            currentUrl: window.location.href,
            config: {}
        };
    },
    created() {
        this.config = getConfig(this.currentUrl);
        console.log("Config loaded: ", this.config);
    }
};
</script>

<style scoped>
.btn-primary {
    color: #FFF !important;
}
.accordion-item {
    border: 1px solid #f5f5f5 !important;
    margin-bottom: 8px;
    border-radius: 8px !important;
}
.accordion-header {
    margin-bottom: 0;
    border: none !important;
    border-radius: 8px;
}
.accordion-header h2 {
    font-weight: bolder;
}
.accordion-button {
    border-radius: 8px !important;
    border: none !important;
    box-shadow: none !important;
}
.accordion-button:not(.collapsed) {
    background-color: transparent !important;
    border: none !important;
}
@media only screen and (min-width: 992px) {
    section { text-align: left; }
    section h1 {
        font-size: 3.125em;
        line-height: 48px;
        text-align: left;
    }
    section p { text-align: left; }
    section h4 { text-align: left; }
}
</style>
