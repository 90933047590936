<template>
 <section class="d-flex align-items-center bg-white pt-0" style="min-height: 90vh;">
                <div class="container d-flex align-items-center">
                    <div class="row d-flex align-items-center">
                        <div class="col-12 col-sm-7 d-flex align-items-center d-flex align-items-center order-1 order-sm-0">   
                                <div id="intro" class="">
                                    <h1 class="mb-3"><strong>Atendimento automatizado</strong> por whatsapp fácil, rápido e <strong>SEM CÓDIGO!</strong></h1>
                                    <p class="mb-3">Marketing automatizado, geração de leads e muito mais utilizando nosso construtor arrasta e solta.
                                    </p>
                                    <a :href="config.signupUrl" class=" animate__animated animate__pulse animate__infinite infinite btn btn-lg btn-intro">
                                        <span v-if="config.testButton.visible">{{config.testButton.textStart}}</span>
                                        <span v-if="!config.testButton.visible">{{config.testButton.textTrial}}</span>
                
                                    </a>  

                                     
                                </div>
                        </div>
                        <div class="offset-3 col-7 offset-sm-0 col-sm-5 order-0 order-sm-1">
                            <img src="/images/tela2.gif" alt="" class="img-fluid"/>
                        </div>
                    </div>
                </div>
            </section>
            <div class="container-fluid" style="background-color: #FFF;">
                <div class="container">
                <div class="row">
                    <div class="col-12">
                        <h3 class="mb-3 text-center">PARCEIROS</h3>
                           <Vue3Carrousel :items="carrouselItems" />     
                    </div>
                    </div>
                </div>
            </div>
</template>

<script>
import Vue3Carrousel from './vue3Carrousel.vue';
import { getConfig } from '@/config';

export default {

  name: 'IntroSite',
  components:  {
   Vue3Carrousel
  },
  props: {
    msg: String
  },
   data() {
    return {
      currentUrl: window.location.href,
      config: {}
    };
  },
  created() {
    this.config = getConfig(this.currentUrl);
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@media only screen and (min-width: 992px) {

    #intro {text-align: left;}
    #intro h1, h1 {
        font-size: 3.125em;
        line-height: 48px;
        text-align: left;
    }
    section p {text-align:left;}
    #intro h4 {text-align: left;}
    .btn-center{width:auto;}
}
.btn-intro{
    color:var(--white-text-color) !important; 
    background-color:var(--green-dark-color)  !important;
}
</style>
