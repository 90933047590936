<template>
  <div>
    <typebot-standard style="height: 100vh;"></typebot-standard>
  </div>
</template>

<script>
export default {
  name: 'AssinePage',
  mounted() {
    this.loadTypebotScript();
  },
  methods: {
    loadTypebotScript() {
      // Verifica se o script já está carregado
      const existingScript = document.querySelector('script[src="https://cdn.jsdelivr.net/npm/@typebot.io/js@0.2.85/dist/web.js"]');
      if (!existingScript) {
        // Adiciona um novo script
        const script = document.createElement('script');
        script.type = 'module';
        script.src = 'https://cdn.jsdelivr.net/npm/@typebot.io/js@0.2.85/dist/web.js';
        script.onload = () => {
          try {
            // Inicializa o Typebot após o carregamento do script
            /* global Typebot */
            Typebot.initStandard({
              typebot: "idoconversa",
              apiHost: "https://bot.crmchat.com.br",
            });
          } catch (error) {
            console.error("Erro ao inicializar o Typebot:", error);
          }
        };
        script.onerror = (error) => {
          console.error("Erro ao carregar o script do Typebot:", error);
        };
        document.body.appendChild(script);
      } else {
        // Inicializa o Typebot se o script já estiver carregado
        try {
          Typebot.initStandard({
            typebot: "idoconversa",
            apiHost: "https://bot.crmchat.com.br",
          });
        } catch (error) {
          console.error("Erro ao inicializar o Typebot:", error);
        }
      }
    }
  }
}
</script>

<style>
.typebot {
  margin-top: 2em; /* Espaçamento de duas linhas */
}
</style>
