<template>
  <header>
    <nav class="navbar fixed-top">
      <div class="container-fluid">
        <a class="navbar-brand" href="#">
          <img :src="config.logoUrl" class="img-fluid" />
        </a>
        <nav class="nav justify-content-center d-none d-sm-flex">
          <router-link class="nav-link" :to="{ path: '/', hash: '#exemplos' }">Exemplos</router-link>
          <router-link class="nav-link" :to="{ path: '/', hash: '#features' }">Funcionalidades</router-link>
          <router-link class="nav-link" :to="{ path: '/', hash: '#planos' }">Planos</router-link>
          <router-link class="nav-link" :to="{ path: '/', hash: '#faq' }">FAQ</router-link>
          <template v-if="isExternalLink(config.signupUrl)">
            <a class="nav-link" :href="config.signupUrl" target="_blank">Assine</a>
          </template>
          <template v-else>
            <router-link class="nav-link" :to="config.signupUrl">Assine</router-link>
          </template>
        </nav>
        <div class="acesso d-flex d-sm-none">
          <a :href="config.chatUrl" class="lock">
            <svg xmlns="http://www.w3.org/2000/svg" height="24" width="21" viewBox="0 0 448 512">
              <path d="M144 144v48H304V144c0-44.2-35.8-80-80-80s-80 35.8-80 80zM80 192V144C80 64.5 144.5 0 224 0s144 64.5 144 144v48h16c35.3 0 64 28.7 64 64V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V256c0-35.3 28.7-64 64-64H80z"/>
            </svg>
          </a>
          <button class="navbar-toggler d-block d-sm-none" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar">
            <span></span>
            <span></span>
            <span></span>
          </button>
        </div>
        <div class="acesso d-none d-sm-block">
          <a :href="config.chatUrl" class="lock">
            <svg xmlns="http://www.w3.org/2000/svg" height="24" width="21" viewBox="0 0 448 512">
              <path d="M144 144v48H304V144c0-44.2-35.8-80-80-80s-80 35.8-80 80zM80 192V144C80 64.5 144.5 0 224 0s144 64.5 144 144v48h16c35.3 0 64 28.7 64 64V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V256c0-35.3 28.7-64 64-64H80z"/>
            </svg>
          </a>
          <template v-if="isExternalLink(config.signupUrl)">
            <a :href="config.signupUrl" class="btn btn-register" target="_blank">
              <div class="label1">
                <span v-if="config.testButton.visible">{{ config.testButton.textStart }}</span>
                <span v-if="!config.testButton.visible">{{ config.testButton.textTrial }}</span>
              </div>
              <div class="label2">Criar conta</div>
            </a>
          </template>
          <template v-else>
            <router-link class="btn btn-register" :to="config.signupUrl">
              <div class="label1">
                <span v-if="config.testButton.visible">{{ config.testButton.textStart }}</span>
                <span v-if="!config.testButton.visible">{{ config.testButton.textTrial }}</span>
              </div>
              <div class="label2">Criar conta</div>
            </router-link>
          </template>
        </div>
        <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
          <div class="offcanvas-header">
            <h5 class="offcanvas-title" id="offcanvasNavbarLabel">{{ config.nameTitle }}</h5>
            <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
          </div>
          <div id="menu-mobile" class="offcanvas-body">
            <ul class="navbar-nav justify-content-end flex-grow-1 pe-3">
              <li class="nav-item" data-bs-dismiss="offcanvas">
                <router-link class="nav-link" :to="{ path: '/' }">Início</router-link>
              </li>
              <li class="nav-item" data-bs-dismiss="offcanvas">
                <router-link class="nav-link" :to="{ path: '/', hash: '#exemplos' }">Exemplos</router-link>
              </li>
              <li class="nav-item" data-bs-dismiss="offcanvas">
                <router-link class="nav-link" :to="{ path: '/', hash: '#features' }">Funcionalidades</router-link>
              </li>
              <li class="nav-item" data-bs-dismiss="offcanvas">
                <router-link class="nav-link" :to="{ path: '/', hash: '#planos' }">Planos</router-link>
              </li>
              <li class="nav-item" data-bs-dismiss="offcanvas">
                <router-link class="nav-link" :to="{ path: '/', hash: '#faq' }">FAQ</router-link>
              </li>
              <li class="nav-item" data-bs-dismiss="offcanvas">
                <template v-if="isExternalLink(config.signupUrl)">
                  <a :href="config.signupUrl" class="btn btn-register" target="_blank">
                    <div class="label1">
                      <span v-if="config.testButton.visible">{{ config.testButton.textStart }}</span>
                      <span v-if="!config.testButton.visible">{{ config.testButton.textTrial }}</span>
                    </div>
                    <div class="label2">Criar conta</div>
                  </a>
                </template>
                <template v-else>
                  <router-link class="btn btn-register" :to="config.signupUrl">
                    <div class="label1">
                      <span v-if="config.testButton.visible">{{ config.testButton.textStart }}</span>
                      <span v-if="!config.testButton.visible">{{ config.testButton.textTrial }}</span>
                    </div>
                    <div class="label2">Criar conta</div>
                  </router-link>
                </template>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  </header>
</template>

<script>
import { getConfig } from '@/config';

export default {
  name: 'NavBar',
  data() {
    const currentUrl = window.location.href;
    const config = getConfig(currentUrl);
    return {
      config
    };
  },
  methods: {
    isExternalLink(url) {
      // Verifica se a URL é externa
      return /^(http|https):\/\//.test(url);
    }
  }
};
</script>

<style scoped>
nav {
  background-color: #fff;
  padding: 4px;
}
nav a {
  color: #252525 !important;
}
.navbar-brand img {
  max-width: 125px;
}
.btn.btn-register {
  background-color: var(--green-dark-color);
  color: #fff !important;
  font-weight: 600;
  border-radius: 150px;
  overflow: hidden;
  height: 60px;
}
.btn.btn-register:hover {
  background-color: var(--green-dark-color);
}
.btn-register .label1 {
  position: relative;
  top: 0;
  opacity: 1;
}
.btn.btn-register:hover .label1 {
  top: -48px;
  opacity: 0;
}
.btn-register .label2 {
  position: relative;
  top: 12px;
  opacity: 0;
}
.btn-register:hover .label2 {
  position: relative;
  top: -24px;
  opacity: 1;
}
.acesso {
  display: flex;
}
.acesso .lock {
  color: #525252 !important;
  font-size: 1.8rem;
  padding: 8px 16px;
  opacity: 0.35;
}
.acesso .lock:hover {
  opacity: 1;
}
button.navbar-toggler {
  border: none;
  min-width: 55px;
}
.navbar-toggler span {
  display: block;
  width: 100%;
  height: 2px;
  margin: 6px;
  background-color: #525252;
}
.navbar-toggler:hover span {
  margin-left: 3px;
}
.navbar-toggler:hover span:nth-child(odd) {
  margin-left: 0;
}
.navbar-toggler:hover span:nth-child(even) {
  margin-left: 3px;
}
@media only screen and (max-width: 575px) {
  .acesso .lock[data-v-4295d220] {
    font-size: 0;
    padding: 5px 0;
  }
  .navbar-brand img {
    max-width: 95px;
  }
}
</style>
